import React, { useState, useEffect, Fragment } from "react";
import ContactReceiver from "../../contexts/contact_context/Contact_Receiver";
import ChainedContext from "../../contexts/chainedContext";
import API from "../../api";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { Button, Input } from "../../components/Forms";
import Segment from "../../components/Segment";
import { CheckIcon, PencilIcon, SaveAsIcon, XIcon } from "@heroicons/react/solid";
import ClientBreadcrumbs from "./components/ClientBreadcrumbs";
import dayjs from "dayjs";
import RatesTable from "../../components/Tables/ratesTable";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import { v4 as uuid } from "uuid";
import { GiMoneyStack } from "react-icons/gi";

function Settings({ contact }) {
  const [isOpen, setIsOpen] = useState(false);
  const [initLoad, setInitLoad] = useState(false);
  const [feesModal, setFeesModal] = useState(false);
  const [showSelectSet, setShowSelectSet] = useState(false);
  const [clioUsers, setClioUsers] = useState([]);
  const [field, setField] = useState("");
  const [value, setValue] = useState("");
  const [unedited, setUnedited] = useState(true);
  const [baseRate, setBaseRate] = useState({});
  const [feeSets, setFeeSets] = useState([]);
  const [data, setData] = useState({
    "fee_set": "",
    "upfront_fee": contact.settings.upfront_fee ? contact.settings.upfront_fee : 0,
    "upfront_fee_type": contact.settings.upfront_fee_type ? contact.settings.upfront_fee_type : "fixed",
    "fee_thresholds": contact.settings.fee_thresholds ? contact.settings.fee_thresholds : [],
    scale: contact.settings.scale ? contact.settings.scale : null,
    interest_rate: 0,
  })

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [openInterestModal, setOpenInterestModal] = useState(false)
  const [interestModalStage, setInterestModalStage] = useState("");
  const [latePaymentsBaseRate, setLatePaymentsBaseRate] = useState({});

  const UpdateFees = () => {
    API.contacts
      .updateSettings(contact._id, data)
      .then(() => {
        toast.success(`Fees updated.`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        contact._refresh();
        setFeesModal(false);
      });
  }

  const UpdateSettings = () => {
    let data = {
      [field]: value,
    };
    API.contacts
      .updateSettings(contact._id, data)
      .then(() => {
        toast.success(`${field} setting updated.`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        contact._refresh();
        setIsOpen(false);
      });
  };

  const getData = () => {
    if(!initLoad){
      Promise.all([
        API.settings.base_rates.normal.get(),
        API.settings.fee_scales().list(),
        API.users.getAll()
      ]).then((res) => {
        if(res[0][0]){
          setBaseRate(res[0][0]);
        }

        if(res[2]){
          setClioUsers(
            res[2].users.map((a, index) => ({
              key: index,
              text: a.name,
              value: a._clioId,
              rate: a.rate
            }))
          );
        }

        setFeeSets(res[1]);
        setInitLoad(true);
        
      });
    }
  }

  useEffect(() => {

    API.settings.base_rates.late_payments.get().then((res )=> {
      let f2 = res.filter(e => dayjs().diff(e.date) >= 0);

      if(f2[0]){
        setLatePaymentsBaseRate(f2[0]);
      }else{
        setLatePaymentsBaseRate(res[0]);
      }
    })

    getData();
  }, []);

  const SettingDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed z-10 inset-0 overflow-y-auto"
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className=" inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="text-xl">
                <Dialog.Title>
                  Updating: <span className="text-primary italic">{field}</span>{" "}
                </Dialog.Title>
              </div>
              <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="flex flex-row gap-2">
                  <label className="text-primary p-2 ">{field} : </label>
                  <Input
                    name={field}
                    value={
                      value === "" && unedited === true
                        ? contact.settings[field]
                          ? contact.settings[field]
                          : ""
                        : value
                    }
                    onChange={(e) => {
                      setValue(e.target.value);
                      setUnedited(false);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                <Button
                  colour="alert"
                  label="Cancel"
                  onClick={() => setIsOpen(false)}
                  icon={XIcon}
                />

                <Button
                  colour="positive"
                  label="Confirm"
                  onClick={UpdateSettings}
                  icon={CheckIcon}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const FeesDialog = () => {
    return (
      <Transition
        show={feesModal}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {showSelectSet && 
        <ConfirmModal
          handleClose={() => setShowSelectSet(false)}
          open={true}
          header={"Load Fee Set?"}
          content={
            "Are you sure you like to load this Fee Set, all fee values will be rest with this set values. This data is only saved once Fees is confirmed!"
          }
          handleSubmit={() => {
            let set = feeSets.find(set => set._id.toString() === data.fee_set);

            setData({ 
              ...data, 
              upfront_fee: set.upfront_fee,
              upfront_fee_type: set.upfront_fee_type,
              fee_thresholds: set.thresholds,
              scale: set.scale,
            });
            setShowSelectSet(false);
          }}
        /> }
        <Dialog
          open={feesModal}
          onClose={() => setFeesModal(false)}
          className="fixed z-9 inset-0 overflow-y-auto"
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className=" inline-block w-full max-w-5xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="text-xl">
                <Dialog.Title>
                  Set/Override Fees
                  <div className="floated-right">
                    <select
                      name="fee_set"
                      value={data.fee_set}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md bg-gray-200"
                      placeholder={"Select"}
                      onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                    >
                      <option disabled selected={data.fee_set == ""} hidden value="">Choose an option</option>
                      {feeSets.map((set, index) => { return <option key={index} value={set._id.toString()}>{set.name} ({set.scale})</option>})}
                    </select>
                    <Button
                      disabled={!data.fee_set}
                      colour="alert"
                      label="Load Set"
                      onClick={() => setShowSelectSet(true)}
                      icon={SaveAsIcon}
                    />
                    
                  </div>
                </Dialog.Title>
              </div>
              <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="upfront_fee"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Upfront Fee (setup / LBA)
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="number"
                      name="upfront_fee"
                      id="name"
                      value={data.upfront_fee}
                      onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                      className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:max-w-xs sm:text-sm"
                    />
                    <select
                        name="upfront_fee_type"
                        value={data.upfront_fee_type}
                        className="mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md bg-gray-200"
                        placeholder={"Select"}
                        onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })}
                      >
                        <option value={"fixed"}>
                            £
                        </option>
                        <option value={"percent"}>
                            %
                        </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="slidingScale"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Sliding Scale for Success Fees:
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input 
                      type="radio"
                      name="feeScale" // Group name to allow only one selection
                      value="slidingScale"
                      checked={data.scale === 'slidingScale'}
                      onChange={() => setData({ ...data, scale: 'slidingScale', fee_thresholds: [] })} // Update state
                    />
                  </div>
                </div>

                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="stageScale"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Stage Scale for Success Fees:
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input 
                      type="radio"
                      name="feeScale" // Same name for the radio group
                      value="stageScale"
                      checked={data.scale === 'stageScale'}
                      onChange={() => setData({ ...data, scale: 'stageScale', fee_thresholds: [] })}
                    />
                  </div>
                </div>

                {/*

                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="hourlyScale"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Hourly Scale for Success Fees:
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input 
                      type="radio"
                      name="feeScale" // Same name for the radio group
                      value="hourlyScale"
                      checked={data.scale === 'hourlyScale'}
                      onChange={() => setData({ ...data, scale: 'hourlyScale', fee_thresholds: clioUsers.map(c => {
                        return {
                          _id: "custom_" + uuid(),
                          stage: -1,
                          fee: c.rate ? c.rate : 0,
                          threshold: "0.01",
                          over: false,
                          type: "fixed",
                          user: c.value,
                          scale: 'hourlyScale',
                        }
                      })  })}
                    />
                  </div>
                </div> */ }
              </div>

              <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="flex flex-row gap-2">
                  <label
                    htmlFor="upfront_fee"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Rates:
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <RatesTable scale={data.scale} users={clioUsers} rates={data.fee_thresholds.sort((a, b) =>
                    a.threshold > b.threshold ? 1 : a.threshold < b.threshold ? -1 : 0
                  )} 
                  onCreate={(d) => {
                    let thresholds = data.fee_thresholds;

                    thresholds.push({
                      _id: "custom_" + uuid(),
                      fee : d.fee,
                      over_threshold : d.over,
                      threshold : d.threshold,
                      type : d.type,
                      stage : d.stage,
                      scale : d.scale,
                      user : d.user,
                    })

                    return setData({...data, fee_thresholds: thresholds})

                  }}
                  onUpdate={(id, d) => {
                    let thresholds = data.fee_thresholds;
                    let threshold = thresholds.find(item => item._id == id);

                    if(threshold) {
                      threshold.fee = d.fee;
                      threshold.over_threshold = d.over;
                      threshold.threshold = d.threshold;
                      threshold.type = d.type;
                      threshold.stage = d.stage;
                      threshold.user = d.user;
                      
                    }

                    return setData({...data, fee_thresholds: thresholds})

                  }}
                  onDelete={(id) => {
                    let thresholds = data.fee_thresholds;
                    let threshold = thresholds.findIndex(item => item._id == id);

                    if(threshold != -1){
                      thresholds.splice(threshold, 1);
                    }

                    return setData({...data, fee_thresholds: thresholds})
                  }} />
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                <Button
                  colour="alert"
                  label="Cancel"
                  onClick={() => setFeesModal(false)}
                  icon={XIcon}
                />

                <Button
                  colour="positive"
                  label="Confirm"
                  onClick={UpdateFees}
                  icon={CheckIcon}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const interestModal = () => {
    return (
      <Transition.Root show={openInterestModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenInterestModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                      <GiMoneyStack className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Interest Rate
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Contract with Interest Specified?
                          <span className="ml-2 isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              className={`${interestModalStage.includes("contractual") && "bg-primary"} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => setInterestModalStage("contractual")}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`${interestModalStage.includes("bussiness") && "bg-primary"} relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => setInterestModalStage("bussiness")}
                            >
                              No
                            </button>
                          </span>
                        </p>
                        {interestModalStage && interestModalStage.includes("contractual") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Specified flat rate?
                          <span className="ml-2 isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              className={`${interestModalStage == "contractual_flat" && "bg-primary"} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => setInterestModalStage("contractual_flat")}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`${interestModalStage == "contractual_nonflat" && "bg-primary"} relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => setInterestModalStage("contractual_nonflat")}
                            >
                              No
                            </button>
                          </span>
                        </p>}
                        {interestModalStage && interestModalStage == "contractual_flat" &&
                        <p className="text-sm text-gray-500 mt-2">
                          <Input
                            label="Flat Rate"
                            name="interest_rate"
                            value={data.interest_rate}
                            onChange={onChange}
                          />
                        </p>}
                        {interestModalStage && interestModalStage == "contractual_nonflat" &&
                        <p className="text-sm text-gray-500 mt-2">
                          <Input
                            label="Percentage Specified"
                            name="interest_rate_percent"
                            value={data.interest_rate_percent}
                            onChange={(e) => {
                              setData({ 
                                ...data, 
                                [e.target.name]: e.target.value,
                                interest_rate: e.target.value * (data.interest_rate_base ? data.interest_rate_base : 1)
                                });
                            }}
                          />
                          <Input
                            label="Base Rate"
                            name="interest_rate_base"
                            value={data.interest_rate_base}
                            onChange={(e) => {
                              setData({ 
                                ...data, 
                                [e.target.name]: e.target.value,
                                interest_rate: e.target.value * (data.interest_rate_percent ? data.interest_rate_percent : 1)
                                });
                            }}
                          />
                          Calculated Rate: {data.interest_rate}
                        </p>}
                        {interestModalStage && interestModalStage.includes("bussiness") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Business to Business contract?
                          <span className="ml-2 isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              className={`${interestModalStage == "bussiness_bussiness" && "bg-primary"} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("bussiness_bussiness");
                                setData({ 
                                  ...data, 
                                  interest_rate: (latePaymentsBaseRate ? latePaymentsBaseRate.rate : 0) //+ (baseRate ? baseRate.rate : 0)
                                });
                              }}
                            >
                              Yes
                            </button>
                            <button
                              type="button"
                              className={`${interestModalStage == "bussiness_simple" && "bg-primary"} relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary`}
                              onClick={() => {
                                setInterestModalStage("bussiness_simple");
                                setData({ 
                                  ...data, 
                                  interest_rate: 8
                                });
                              }}
                            >
                              No
                            </button>
                          </span>
                        </p>}
                        {interestModalStage && (interestModalStage == "bussiness_simple") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Simple interest rate at 8%  (s69 County Court Act 1984/s35a Senior Courts Act 1981)
                        </p>}
                        {interestModalStage && (interestModalStage == "bussiness_bussiness") &&
                        <p className="text-sm text-gray-500 mt-2">
                          Late Payment of Commerical Debts (Interest) Act applies (8% + Base): {(latePaymentsBaseRate && latePaymentsBaseRate.rate)} <br/>
                          Current Interest Base rate of Bank of England: {baseRate && baseRate.rate} <br/>
                          Calculated Rate: {data.interest_rate}
                        </p>}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                  <Button
                    label="Update"
                    colour="warning"
                    onClick={() => {
                      API.contacts
                      .updateSettings(contact._id, {interest_rate: data.interest_rate})
                      .then(() => {
                        toast.success(`Interest Rate setting updated.`);
                      })
                      .catch((err) => {
                        console.log(err);
                      })
                      .finally(() => {
                        contact._refresh();
                        setOpenInterestModal(false);
                      });
                    } }
                  />
                </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  };

  return (
    <div>
      {SettingDialog()}
      {FeesDialog()}
      {interestModal()}
      <ClientBreadcrumbs panes={[
        { name: `Client - ${contact && `${contact.name ? contact.name : `${contact.first_name} ${contact.last_name}`} ${contact.suffix ? contact.suffix : ""}`}`, href: `/contacts/${contact._id}`, current: false },
        { name: "Settings", href: `/contacts/${contact._id}/settings`, current: true},
      ]} />
      <Segment className="mt-4">
        <div className="divide-y divide-gray-300">
          <div className="space-y-1">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Client Settings
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">
              Change the client settings
            </p>
          </div>
          <div className="mt-2">
            <dl className="divide-y divide-gray-300 w-full">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 my-auto">
                  Payment Terms
                </dt>
                <dd className="my-auto text-sm text-gray-900">
                  <span>{contact.settings.paymentTerms} Days</span>
                </dd>
                <dd>
                  <Button
                    onClick={() => {
                      setIsOpen(true);
                      setField("paymentTerms");
                    }}
                    label="Update"
                    className="block ml-auto"
                    colour="action"
                    icon={PencilIcon}
                  />
                </dd>
              </div>
            </dl>
          </div>
          <div className="mt-2">
            <dl className="divide-y divide-gray-300 w-full">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 my-auto">
                  Interest Rate
                </dt>
                <dd className="my-auto text-sm text-gray-900">
                  <span>{contact.settings.interest_rate ? contact.settings.interest_rate : data.interest_rate ? data.interest_rate : `Default PLC rate of ${baseRate ? `${baseRate.rate} (${dayjs(baseRate.date).format("DD/MM/YYYY")})` : ""}` }%</span>
                </dd>
                <dd>
                  <Button
                    onClick={() => setOpenInterestModal(true)
                      // setIsOpen(true);
                      // setField("interest_rate");
                    }
                    label="Update"
                    className="block ml-auto"
                    colour="action"
                    icon={GiMoneyStack}
                  />

                </dd>
              </div>
            </dl>
          </div>
          <div className="mt-2">
            <dl className="divide-y divide-gray-300 w-full">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500 my-auto">
                  Fees
                </dt>
                <dd className="my-auto text-sm text-gray-900">
                  <span>
                    <ul>
                      <li> Upfront Fee (setup / LBA): {" "}
                        {contact.settings.upfront_fee ? `${contact.settings.upfront_fee_type == "fixed" ? "£" : ""}${contact.settings.upfront_fee}${contact.settings.upfront_fee_type == "percent" ? "%" : ""}` : `Not Set!!`} 
                      </li>
                      {contact.settings.scale && contact.settings.scale.scale == "slidingScale" && "Sliding Scale for Success Fee:"}
                      {contact.settings.scale && contact.settings.scale.scale == "stageScale" && "Stage Scale for Success Fees:"}
                      {contact.settings.scale && contact.settings.scale.scale == "hourlyScale" && "Hourly Scale for Success Fees:"}
                        {contact.settings.fee_thresholds && contact.settings.fee_thresholds.length > 0 ? 
                        contact.settings.fee_thresholds.map((fee, index) => <li key={index}>
                        - 
                        {contact.settings.scale && contact.settings.scale == "stageScale" ? <>
                          Stage: {fee.stage} - Fee: £{fee.fee.toLocaleString()}
                        </> :
                        <>
                          {`${fee.over_threshold ? "Over:" : "Up to:"} £${fee.threshold.toLocaleString()}`} - Fee: {fee.type === "fixed" ? "£" : ""}{fee.fee.toLocaleString()}{fee.type === "percent" ? "%" : ""}
                        </>}
                         
                      </li>) : 
                        <li>No Threshold Fees Set!!</li>}
                      {/* <li>Up to: £200 - £80 fee</li> */}
                    </ul>
                  </span>
                </dd>
                <dd>
                  <Button
                    onClick={() => {
                      setFeesModal(true);
                    }}
                    label="Set Fees"
                    className="block ml-auto"
                    colour="warning"
                    icon={PencilIcon}
                  />
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </Segment>
    </div>
  );
}
export default ChainedContext(Settings, [
  [
    ContactReceiver,
    (contact) => {
      return { contact };
    },
  ],
]);
