import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Button,
  DateSelect,
  Input,
  SelectTwo,
} from "../../../components/Forms";
import { useParams } from "react-router-dom";

import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
// import {InterestTypes} from "../../../../../_lists";
import API from "../../../api";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
// import DocumentsUploader from "../../../_components/DocumentsUploader";
import CommonFunctions from "../../../CommonFunctions";

const resetData = {
  invoice_num: "",
  reference: "",
  issueDate: dayjs(),
  //days not paid?
  xeroInvoiceNumber: "",
  amount: 0,
  feeAmount: 0,
  dueDate: dayjs(),     
  paymentType: "",
  amountPaid: 0,
  paidDate: dayjs(),
  feeBalance: 0,
  VAT: 0.2,
  TaxRate: "",

  status: "waiting",
  currency: "GBP",

};
export default function Add_Fee_Form({ data, validateSubmit, edit = false, history, matter_id, fee_id = null}) {
  const params = useParams();
  const [isUploading, setIsUploading] = useState(false);
  const [showDocumentUploader, setShowDocumentUploader] = useState(true);
  const [fee, setFee] = useState(resetData);
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});

  const currentYear = (new Date()).getFullYear();
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

  // const years = range(1990, getYear(new Date()) + 1, 1);
  const years = range(currentYear + 6, currentYear - 12, -1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {

    if(mountLoading){
      API.organisations.getMyOrg().then(r => {

        let vat = 0;
        let tax = "";

        switch(r.xeroTaxRate){
            case "20percent":
                vat = 0.2;
                tax = "20%";
              break;
            case "5percent":
                vat = 0.05;
                tax = "5%";
              break;
            case "exempt":
                vat = 0;
                tax = "Exempt";
              break;
            case "novat":
                vat = 0;
                tax = "No VAT";
              break;
            case "zeroECgoods":
                vat = 0;
                tax = "Zero Rated EC Goods Income";
              break;
            case "zeroECservices":
                vat = 0;
                tax = "Zero Rated EC Services";
              break;
            case "zerorated":
                vat = 0;
                tax = "Zero Rated Income";
              break;
        }

        setFee({...fee, VAT: vat, TaxRate: tax});
      });
    }

    if (mountLoading && params.fee_id && edit) {
      Promise.all([
        API.matters.fees.getOne(params.matter_id, params.fee_id),
        // API.matters.fees.getOne(params.matter_id, params.debtor_id ,params.debt_id)
      ])
        .then((res) => {
         setFee({
          ...res[0],
         })
        })
        .finally(() => setMountLoading(false));
    }else if(mountLoading){
      setMountLoading(false)
    }else{
      setMountLoading(false)
    }

  }, [params, mountLoading]);

  useEffect(() => {
    validate();
  }, [fee]);

  const onChange = (e) => {
      setFee({ ...fee, [e.target.name]: e.target.value });
  };
  const submitData = () => {
    if(isUploading){
      toast.error("Please wait for all documents to finish uploading before before continuing.");
      return;
    }

    if(Object.keys(warnings).length >= 1 || Object.keys(errors).length >= 1){
      toast.error("There are validation issues that must be resolved before continuing.");
      return;
    }

    data(fee);
    setShowDocumentUploader(false);
    setTimeout(() => setShowDocumentUploader(true), 500);
    toast.success(`Fee ${edit ? "Edited" : "Added"} Successfully`);
  };
  const validate = () => {
    let warnings = {};
    if(fee.issueDate && (dayjs(fee.issueDate).diff(dayjs())) > 0){
      warnings = {warn: true, message: "Be Advised that the date selected is in the future!"}
    }
    if(fee.issueDate && (dayjs().diff(dayjs(fee.issueDate), "years")) >= 6){
      warnings = {warn: true, message: "Be Advised that the date selected 6+ years ago!"}
    }
    setWarnings(warnings);

    const errors = {};
    if (fee.amount === 0) errors.amount = true;
    if (!fee.invoice_num) errors.invoice_num = true;
    if (!fee.reference) errors.reference = true;
    if (!fee.dueDate) errors.dueDate = true;
    // if((fee.invoiceDate || fee.interestDate || fee.rateType) && Object.keys(errors).length > 0) {
      // errors.notcomplete = true;
    // }

    setErrors(errors);
    validateSubmit(errors);
    return errors;
  };

  let days = dayjs().diff(dayjs(fee.issueDate), "day");
  let totalOwed = fee.amount;
  let totalPaid = fee.amountPaid;
  let totalFees = fee.feeAmount;

    if(mountLoading){
      return "Loading...";
    }

  return (
    <div className="w-full p-2 ">
      <div className="bg-red-500">
        {warnings && warnings.warn &&
        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8 mb-5">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg">
                <ExclamationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden"><b className="animate-pulse">Warning!</b> {warnings && warnings.message}</span>
                <span className="hidden md:inline"><b className="animate-pulse">Warning!</b> {warnings && warnings.message}</span>
              </p>
            </div>
          </div>
        </div> }
    </div>
      <div className="w-full gap-2">
        <div className="text-center pb-4 font-medium text-lg  border-b border-gray-300 mx-10 mb-2">
          Fee Form
        </div>
        
          <div className="grid grid-cols-4 col-span-2 gap-4 border-r border-gray-100 p-2">

          {/*

            invoice_num
            reference
            issueDate
            xeroInvoiceNumber
            amount
            dueDate
            paymentType
            amountPaid
            paidDate
            feeBalance
            status

          */}

            <Input
              label="PLF Invoice Number"
              name={"invoice_num"}
              onChange={onChange}
              placeholder="inv123"
              value={fee.invoice_num}
              error={errors.invoice_num}
            />

            <Input
              label="Reference"
              name={"reference"}
              onChange={onChange}
              value={fee.reference}
            />

            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">
                Issue Date
              </label>
              <DateSelect
                onChange={(date) =>
                  setFee({
                    ...fee,
                    issueDate: date,
                  })
                }
                value={fee.issueDate ? new Date(fee.issueDate) : ""}
                name="issueDate"
                className={`   relative w-full p-2 ${
                  errors.issueDate ? "border border-red-400" : warnings && warnings.warn ? "border border-blue-500 animate-pulse" : ""
                } `}
              />
            </div>

            <SelectTwo 
            
              label="Payment Type"
              field="paymentType"
              onChange={onChange}
              value={fee.paymentType}
              options={[
                {value: "invoice", text: "Invoice"},
                {value: "credit", text: "Sales Credit"},
              ]}

            />

            {/*
            <div className="w-full">
                Days not paid: xxx
            </div>*/}

            <div>
              <Input
                label="Xero Invoice Number"
                name={"xeroInvoiceNumber"}
                onChange={onChange}
                value={fee.xeroInvoiceNumber}
                // error={errors.xeroInvoiceNumber}
                center={true}
                disabled
              />
              <p><i>Xero Invoice number can't be added/updated manually as this there is an ID associated when sent to Xero!</i></p>

            </div>
            

            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">
                Due Date
              </label>
              <DateSelect
                onChange={(date) =>
                  setFee({
                    ...fee,
                    dueDate: date,
                  })
                }
                value={fee.dueDate ? new Date(fee.dueDate) : ""}
                name="dueDate"
                className={`   relative w-full p-2 ${
                  errors.dueDate ? "border border-red-400" : warnings && warnings.warn ? "border border-blue-500 animate-pulse" : ""
                } `}
              />
            </div>

            {/*  

            <Input
              label="Fee Amount"
              type={"number"}
              name={"feeAmount"}
              onChange={onChange}
              placeholder="11111"
              value={fee.feeAmount}
              error={errors.feeAmount}
              center={true}
            />*/}
            <Input
              label="Amount Due"
              type={"number"}
              name={"amount"}
              onChange={onChange}
              placeholder="11111"
              value={fee.amount}
              error={errors.amount}
              center={true}
            />
            <Input
              label="Amount Paid"
              type={"number"}
              name={"amountPaid"}
              onChange={onChange}
              placeholder="11111"
              value={fee.amountPaid}
              error={errors.amountPaid}
              center={true}
            />

            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">
                Paid Date
              </label>
              <DateSelect
                onChange={(date) =>
                  setFee({
                    ...fee,
                    paidDate: date,
                  })
                }
                value={fee.paidDate ? new Date(fee.paidDate) : ""}
                name="paidDate"
                className={`   relative w-full p-2 ${
                  errors.paidDate ? "border border-red-400" : warnings && warnings.warn ? "border border-blue-500 animate-pulse" : ""
                } `}
              />
            </div>

            <SelectTwo 
            
              label="Status"
              field="status"
              onChange={onChange}
              value={fee.status}
              options={[
                {value: "paid", text: "Paid"},
                {value: "waiting", text: "Waiting"},
              ]}

            />
            
            {/* <div className="col-span-2"> */}
              
              
            {/* </div> */}
          </div>
        {/*  
          <div className="col-span-1">
            {showDocumentUploader && <DocumentsUploader title={"Invoices"} existingDocuments={debt.invoices} isUploadingInProgress={a => setIsUploading(a)} onUploadedDocument={documents => {
              setDebt(a => {
                return {
                  ...a,
                  invoices: documents
                }
              })
            }} />}
          </div>*/}

        <div className="grid grid-cols-2 gap-4 mt-4 p-2">
          <div className="flex justify-between border border-gray-100 p-2">
            {fee.status !== "paid" ? `Days from Issue Date: ${days !== null || days !== undefined ? days : "---"}` : "Paid Off"}
          </div>

          <div className="flex justify-between border border-gray-100 p-2">
            Vat - {fee.TaxRate} (From Organisation Settings) :
            <span>£{totalOwed ? CommonFunctions.toFormattedFixed(parseFloat(totalOwed) * fee.VAT) : "0.00"}</span>
          </div>

        </div>
        <div className="grid grid-cols-3 gap-4 mt-4 p-2">

          <div className="flex justify-between border border-gray-100 p-2">
            Amount Paid:
            <span>£{totalPaid ? CommonFunctions.toFormattedFixed(totalPaid) : "0.00"}</span>
          </div>
          {/*  
          <div className="flex justify-between border border-gray-100 p-2">
            Amount Fee:
            <span>£{totalFees ? CommonFunctions.toFormattedFixed(totalFees) : "0.00"}</span>
          </div>*/}
         
          <div className="flex justify-between border border-gray-100 p-2">
            Amount Due: (ex-vat)
            <span>£{totalOwed ? CommonFunctions.toFormattedFixed(totalOwed) : "0.00"}</span>
          </div>

          <div className="flex justify-between border border-gray-100 p-2">
            Total Fee Due:
            <span>£{fee ? CommonFunctions.toFormattedFixed((parseFloat(totalOwed)  /* + parseFloat(totalFees) */ + (parseFloat(totalOwed) * fee.VAT)) - parseFloat(totalPaid)) : "0.00"}</span>
          </div>
          
        </div>
        <div className="flex justify-between mt-3 border-t border-gray-100 py-4">
          <Button
            colour="alert"
            onClick={() => {
              // setFee(resetData);
              // toast.success("Form reset.");
              history.push(`/matters/${matter_id}/fees${(fee_id ? `/${fee_id}` : '')}`);
            }}
            label="Back"
          />
          <Button 
            colour={edit ? "warning" : "positive"}
            onClick={submitData} 
            // disabled={errors.notcomplete} 
            label={edit ? 'Edit' : 'Add'}
           />
        </div>
      </div>
    </div>
  );
}
